

















import {Component, Prop} from 'vue-property-decorator';
import CustomersList from '@/components/business/customers/CustomersList.vue';
import {Getter} from 'vuex-class';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';

@Component({
  name: 'customers',
  components: {CustomersList}
})
export default class Customers extends mixins(Notifications) {

  @Prop({required: true, default: 'all'}) type!: string;

  @Getter customers;

  get customerItems() {
    if (this.type === 'all') {
      return this.customers;
    }
    if (this.type === 'vip') {
      return this.customers.filter((customer) => customer.vip);
    }
    if (this.type === 'opt-in') {
      return this.customers.filter((customer) => customer.permissions
          && (customer.permissions.contact || customer.permissions.promote));
    }
    return this.customers;
  }
}
